(function ($) {


	// search

	$(".search-toggle").on("click", function () {
		$("body").toggleClass("search-open");
		$(".search-toggle i").toggleClass("toggle");


		// focus on input

		$('.search-input').focus();

		// close menu
		if ($('body').hasClass('menu-open')) {
			$("body").removeClass("menu-open");
			$(".responsive-toggle i").toggleClass("toggle");
		}
	});

	// menu

	$(".responsive-toggle").on("click", function () {
		$("body").toggleClass("menu-open");
		$(".responsive-toggle i").toggleClass("toggle");

		if ($('body').hasClass('search-open')) {
			$("body").removeClass("search-open");
			$(".search-toggle i").toggleClass("toggle");

		}

		$('.menu-item-has-children > a').on('click', function (e) {
			e.preventDefault();
			if (window.innerWidth <= 767) {
				$(this).parent().toggleClass('submenu--open');
			}
		});
		// close search
		//$("body").removeClass("search-open");
		//$(".search-toggle i fa-times").removeClass("toggle");
	});


	$('.js-title-height').matchHeight(false);
	$('.js-icon-height').matchHeight(false);
	$('.js-link-height').matchHeight(false);
	$('.js-content-height').matchHeight(true);

	$('.js-match').matchHeight(true);



	$(document).on('click', '.block-button', function () {



		var listHeight = $(this).parents('.block').find('.link-list').height();

		if ($(this).parents('.block').find('.link-list-wrapper').hasClass('list-open')) {
			$(this).removeClass('block-button-active');
			$(this).parents('.block').find('.link-list-wrapper').css('max-height', 0);
			$(this).parents('.block').find('.link-list-wrapper').toggleClass('list-open');
		} else {
			$(this).addClass('block-button-active');
			$(this).parents('.block').find('.link-list-wrapper').toggleClass('list-open');
			$(this).parents('.block').find('.list-open').css('max-height', listHeight + 20);

		}

	});


	$(document).on('transitionEnd webkitTransitionEnd transitionend oTransitionEnd msTransitionEnd', '.link-list-wrapper', function (e) {

		if ($(this).parents('.block').find('.link-list-wrapper').hasClass('list-open')) {
			//$(this).css('max-height', 9999);
		}

	});


	// Expand Rooms (organisation page)

	$(document).on('click', '.js-expand-room', function (evt) {
		evt.preventDefault();

		var listHeight = $(this).parent('.js-expand-wrapper').find('.room').height();

		$(this).parent('.js-expand-wrapper').find('.expand-content').toggleClass('expanded');
		$(this).toggleClass('close-list');



		if ($(this).parent('.js-expand-wrapper').find('.expand-content').hasClass('expanded')) {
			$(this).html('Sluit');
			$(this).parents('.js-expand-wrapper').find('.expand-content').css('max-height', listHeight + 20);
		} else {
			$(this).html('Bekijk');
			$(this).parents('.js-expand-wrapper').find('.expand-content').css('max-height', 0);

		}

	});

})(jQuery); 	
